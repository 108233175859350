import { SafetyCodeContext } from '@canalplus/types-acm';
import fr_fr from './fr-fr';
import { MyCanalTranslations } from './types';

// File to override sentences for swiss country

const LOCALE_FR_CH: MyCanalTranslations = {
  ...fr_fr,
  SafetyDisclaimer: {
    ...fr_fr.SafetyDisclaimer,
    [SafetyCodeContext.Parental]: {
      ...fr_fr.SafetyDisclaimer[SafetyCodeContext.Parental],
      createText:
        'Le code parental protège l’accès aux programmes réservés à un public adulte averti (programmes interdits aux moins de 18 ans, pornographiques ou de très grandes violences). Pour gérer votre code parental, rendez-vous sur ',
      tooltip:
        "Si vous avez oublié votre code parental, merci de contacter le Service Client au <span> %{phoneNumber} </span> (n° gratuit). Horaire d'ouverture: 8h30 à 18h du lundi au vendredi (hors jours fériés)",

      warningDescription:
        'Ce service est verrouillé car il propose un programme réservé à un public averti (programmes interdits aux moins de 18 ans, pornographiques ou de très grandes violences).',
    },
  },
};

export default LOCALE_FR_CH;
